<template>
  <div class="mt-5">
    <b-container>
      <div>
        <h2 class="font-weight-bolder text-colorBlue">
          Safe
          <span class="text-colorGreen"> Travel</span>
        </h2>
        <p class="tetx-colorText font-weight-bold" style="font-size: 1.2rem">
          we understand the paramount importance of safety and well-being. Our
          services encompass a robust security infrastructure featuring highly
          trained personnel and state-of-the-art surveillance systems. We ensure
          a visible and reliable security presence to deter potential threats,
          providing you with peace of mind.
        </p>
      </div>
    </b-container>
    <div class="bg-colorBlue mt-3 py-4">
      <b-container>
        <b-row>
          <b-col class="">
            <div>
              <h4 class="text-colorGreen font-weight-bolder">
                Medical Emergencies
              </h4>
            </div>
            <div>
              <h5 class="text-light font-weight-bold pt-50 pr-3">
                Benefit from our collaboration with comprehensive health
                packages and an efficient online booking process through Askari
                Guards. Additionally, our Askari Transport Services offer secure
                transportation to and from medical facilities, facilitated by
                our fleet of bulletproof vehicles.
              </h5>
            </div>
          </b-col>
          <b-col class="">
            <div>
              <h4 class="text-colorGreen font-weight-bolder">
                Bulletproof Vehicle Services
              </h4>
            </div>
            <div>
              <h5 class="text-light font-weight-bolder pt-50 pr-3">
                Travel with confidence in our fleet of bulletproof vehicles,
                providing secure transportation with an extra layer of
                protection during transit. Our experienced drivers are skilled
                and trained to prioritize safety, ensuring a smooth and secure
                journey for our clients
              </h5>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="mt-5 mb-5">
      <div>
        <h2 class="font-weight-bolder text-colorBlue text-center">
          Why Choose
          <span class="text-colorGreen"> Security</span>
        </h2>
      </div>
      <div>
        <b-row class="mt-3 d-flex align-items-center justify-content-center">
          <b-col
            align-self="stretch"
            md=""
            :class="[hoveredCol1 ? 'bg-colorBlue my-border' : 'bg-colorGrey']"
            class="mr-0 mr-md-1 mb-1 mb-md-0 image-container-hover"
            @mouseover="hoveredCol1 = true"
            @mouseleave="hoveredCol1 = false"
            style="border-radius: 12px"
          >
            <div class="text-center pt-3">
              <img
                src="@/assets/images/icons/travel/Integrated Services.svg"
                width="120px"
                class="base-img"
              />
              <img
                src="@/assets/images/icons/travel/Integrated Services white.svg"
                width="120px"
                class="hover-img"
              />
            </div>
            <div class="px-1 pt-2 text-center">
              <h4 class="text-colorGreen font-weight-bolder">
                Integrated Services
              </h4>
            </div>
            <div
              class="px-1 pt-50 pb-1 text-center"
              :class="[hoveredCol1 ? 'text-light ' : 'text-dark']"
              @mouseover="hoveredCol1 = true"
              @mouseleave="hoveredCol1 = false"
            >
              <p class="font-weight-bold">
                Our comprehensive approach seamlessly combines security and
                various assistance services.
              </p>
            </div>
          </b-col>
          <b-col
            align-self="stretch"
            md=""
            :class="[hoveredCol2 ? 'bg-colorBlue my-border' : 'bg-colorGrey']"
            class="mr-0 mr-md-1 mb-1 mb-md-0 image-container-hover"
            @mouseover="hoveredCol2 = true"
            @mouseleave="hoveredCol2 = false"
            style="border-radius: 12px"
          >
            <div class="text-center pt-3">
              <img
                src="@/assets/images/icons/travel/Professionalism.svg"
                width="120px"
                class="base-img"
              />
              <img
                src="@/assets/images/icons/travel/Professionalism white.svg"
                width="120px"
                class="hover-img"
              />
            </div>
            <div class="px-1 pt-2 text-center">
              <h4 class="text-colorGreen font-weight-bolder">
                Professionalism
              </h4>
            </div>
            <div
              class="px-1 pt-50 pb-1 text-center"
              :class="[hoveredCol2 ? 'text-light ' : 'text-dark']"
              @mouseover="hoveredCol2 = true"
              @mouseleave="hoveredCol2 = false"
            >
              <p class="font-weight-bold">
                Count on our trained personnel dedicated to delivering
                high-quality services.
              </p>
            </div>
          </b-col>
          <b-col
            align-self="stretch"
            md=""
            :class="[hoveredCol3 ? 'bg-colorBlue my-border' : 'bg-colorGrey']"
            class="mr-0 mr-md-1 mb-1 mb-md-0 image-container-hover"
            @mouseover="hoveredCol3 = true"
            @mouseleave="hoveredCol3 = false"
            style="border-radius: 12px"
          >
            <div class="text-center pt-3">
              <img
                src="@/assets/images/icons/travel/Cutting-Edge Technology.svg"
                width="120px"
                class="base-img img-fluid"
              />
              <img
                src="@/assets/images/icons/travel/Cutting-Edge Technology white.svg"
                width="120px"
                class="hover-img"
              />
            </div>
            <div class="px-1 pt-2 text-center">
              <h4 class="text-colorGreen font-weight-bolder">
                Cutting Edge Technology
              </h4>
            </div>
            <div class="px-1 py-50 text-center">
              <p class="font-weight-bold">
                Your safety is our priority. Partner with us for a secure
                future, and let us safeguard what matters most to you. Contact
                us today for a consultation and experience the peace of mind
                that comes with our comprehensive security and assistance
                services.
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container>
      <h2 class="mt-2 mb-3 font-weight-bolder text-colorBlue">
        SecurityPackages
      </h2>
      <b-row class="">
        <b-col
          md="6"
          lg="4"
          v-for="(secuirtyPackage, index) in secuirtyPackages"
          :key="index"
          class="d-flex flex-column justify-content-between"
          :class="{ 'bg-colorGrey rounded': isHovered === index }"
          @mouseover="isHovered = index"
          @mouseleave="isHovered = null"
          align-self="stretch"
        >
          <div class="d-flex justify-content-between px-2 align-items-center">
            <div>
              <h5 class="font-weight-bolder text-colorText text-center">
                {{ secuirtyPackage.name }}
              </h5>
            </div>
            <div class="text-center">
              <p
                class="font-weight-bolder text-colorText m-0 p-0 text-center"
                style="font-size: 0.7rem"
              >
                starting from
              </p>
              <h2 class="font-weight-bolder text-colorGreen text-center">
                {{ secuirtyPackage.amount }}$
              </h2>
            </div>
          </div>

          <div class="px-2">
            <p
              v-html="secuirtyPackage.description"
              class="font-weight-bold text-colorText text-left"
            ></p>
          </div>
          <!-- <div class="text-center">
            <b-button
              block
              rounded
              :variant="isInCart(secuirtyPackage) ? 'colorBlue' : 'colorGreen'"
              size="md"
              class="font-weight-bolder px-5 mb-sm-1"
              @click="handleCartAction(secuirtyPackage)"
                    >
                      {{ isInCart(secuirtyPackage) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import util from "@/util.js";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util],

  data() {
    return {
      isHovered: null,
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      secuirtyPackages: [],
    };
  },
  async mounted() {
    try {
      const res = await this.getSecurityPublic({});
      if (res.status === 200) {
        this.secuirtyPackages = res.data;
        console.log(this.secuirtyPackages);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart, // Assuming your cart is stored in appData module of Vuex
    }),
  },
  methods: {
    ...mapActions({
      getSecurityPublic: "appData/getSecurityPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
    }),
    addCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.SAFE_TRAVEL}`;
      const isInCart = this.cart.some((cartItem) => cartItem.id === cartItemId);

      if (!isInCart) {
        this.addToCart({
          id: `${item.id}_${this.cartItemType.SAFE_TRAVEL}`,
          name: item.name,
          itemId: item.id,
          price: item.amount,
          description: item.description,
          type: this.cartItemType.SAFE_TRAVEL,
          quantity: 1,
          numberOfDays: 1,
        });
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Item added to cart successfully",
              variant: "success",
              icon: "BellIcon",
            },
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Assuming you have a route named 'Cart'
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.SAFE_TRAVEL}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.my-border {
  border-bottom: 8px solid #06bd46;
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
